import * as THREE from "three";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import {
  Html,
  Environment,
  useGLTF,
  ContactShadows,
  OrbitControls,
} from "@react-three/drei";
import kitchenImage from "../../assets/Component 23.png";
import bedroomImage from "../../assets/Component 8.png";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const Modelsmall = () => {
  const group = useRef();
  const gltf = useLoader(GLTFLoader, "/3dmodel.glb");
  // useFrame((state) => {
  //   const t = state.clock.getElapsedTime();
  //   group.current.rotation.x = THREE.MathUtils.lerp(
  //     group.current.rotation.x,
  //     Math.cos(t / 2) / 20 + 0.25,
  //     0.1
  //   );
  //   group.current.rotation.y = THREE.MathUtils.lerp(
  //     group.current.rotation.y,
  //     Math.sin(t / 1) / 20,
  //     0.1
  //   );
  //   group.current.rotation.z = THREE.MathUtils.lerp(
  //     group.current.rotation.z,
  //     Math.sin(t / 8) / 30,
  //     0.5
  //   );
  //   group.current.position.y = THREE.MathUtils.lerp(
  //     group.current.position.y,
  //     (-2 + Math.sin(t / 2)) / 2,
  //     0.1
  //   );
  // });
  return (
    <group dispose={null} ref={group} scale={[1, 1, 1]}>
      <primitive object={gltf.scene}>
        <mesh
          geometry={
            gltf.nodes["Plant_Basil_A_spring-summer-autumn.019"]?.geometry
          }
          material={"Stem_Green_bqm"}
        >
          <Html
            className="absolute content"
            center
            position={[Math.PI + 12, Math.PI + 0.1, Math.PI - 8.6]}
            // sprite
            transform
            rotation-y={-Math.PI}
          >
            {/* <div className="absolute top-0 w-[500px] left-[280px]"> */}
            <a href="/kitchen">
              {/* <img src={bedroomImage} alt="" className="w-full" /> */}
              <span className="text-4xl  text-mainColor px-[50px] py-[8px] bg-opacity-50">
                kitchen.
              </span>
            </a>
            {/* </div> */}
          </Html>
          <Html
            className="relative content"
            rotation-y={-Math.PI}
            center
            position={[Math.PI + 9, Math.PI + 6.0, Math.PI - 9.7]}
            // sprite
            transform
          >
            {/* <div className="absolute top-0 w-[500px] left-[280px]"> */}
            <a href="/closet">
              {/* <img src={bedroomImage} alt="" className="w-full" /> */}
              <span className="text-4xl  text-mainColor px-[60px] py-[8px] bg-opacity-50">
                closet.
              </span>
            </a>
            {/* </div> */}
          </Html>

          <Html
            className="relative content"
            // rotation-x={-Math.PI / 2}
            position={[Math.PI - 15.2, Math.PI - 1.3, Math.PI + 7.7]}
            transform
            center

            // sprite
          >
            <div
              className="relative wrapper"
              onPointerDown={(e) => e.stopPropagation()}
            >
              {/* <div className="absolute top-[230px] left-[1300px]"> */}
              <a href="/bathroom">
                <span className="text-4xl  text-mainColor px-[29px] py-[8px] bg-opacity-50">
                  bathroom.
                </span>
              </a>
            </div>
            {/* </div> */}
          </Html>
          <Html
            className="relative content"
            // rotation-x={-Math.PI / 2}
            position={[Math.PI + 9.2, Math.PI - 1.4, Math.PI + 6.7]}
            transform
            center
            // sprite
          >
            <div
              className="relative wrapper"
              onPointerDown={(e) => e.stopPropagation()}
            >
              {/* <div className="absolute -top-[100px] w-[600px] left-[1090px]"> */}
              <a href="/livingroom">
                {/* <img src={bedroomImage} alt="" className="w-full" /> */}
                <span className="text-4xl  text-mainColor px-[18px] py-[8px] bg-opacity-50">
                  livingRoom.
                </span>
              </a>
            </div>
            {/* </div> */}
          </Html>
          <Html
            className="relative content"
            // rotation-x={-Math.PI / 2}
            position={[Math.PI + 9.2, Math.PI + 3.4, Math.PI + 5.7]}
            // sprite

            transform
            center
          >
            <div
              className="relative wrapper"
              onPointerDown={(e) => e.stopPropagation()}
            >
              {/* <div className="absolute -top-[100px] w-[600px] left-[1090px]"> */}
              <a href="/bedroom">
                {/* <img src={bedroomImage} alt="" className="w-full" /> */}
                <span className="text-4xl  text-mainColor px-[35px] py-[8px] bg-opacity-50">
                  bedroom.
                </span>
              </a>
              {/* </div> */}
            </div>
          </Html>
        </mesh>
      </primitive>
    </group>
  );
};

export default function Render3D(props) {
  const [zoom, setZoom] = useState([-15, 16, -30]);

  useEffect(() => {
    setInterval(() => {
      setZoom([zoom[0] + 5, zoom[1] + 5]);
    }, 1000);
  }, []);
  return (
    // flex items-end justify-center
    <>
      <section
        className={`h-full lg:w-full lg:px-10 lg:mx-5 lg:h-3/4 hidden lg:block`}
      >
        <Canvas camera={{ position: zoom, fov: 70 }}>
          <pointLight position={[10, 10, 10]} intensity={5} />
          <Suspense fallback={null}>
            <group rotation={[0, Math.PI, 0]} position={[0, 0, 0]}>
              <Modelsmall className="hidden lg:block" />
              {/* <Modelsmall  className="block lg:hidden"/> */}
            </group>
            <Environment preset="city" />
          </Suspense>
          {/* <ContactShadows
          position={[-10, 0, -30]}
          scale={50}
          blur={2}
          far={4.5}
        /> */}
          <OrbitControls
            enablePan={false}
            enableZoom={false}
            minPolarAngle={Math.PI / 2.8}
            maxPolarAngle={Math.PI / 2.8}
            enableDamping={false}
          />
        </Canvas>
      </section>
      <section
        className={`h-full lg:w-full lg:px-10 lg:mx-5 lg:h-3/4 block lg:hidden`}
      >
        <Canvas camera={{ position: zoom, fov: 70 }} className="!w-screen">
          <pointLight position={[10, 10, 10]} intensity={5} />
          <Suspense fallback={null}>
            {props.orientation !== 0 ? (
              <group
                rotation={[0, Math.PI, 0]}
                position={[0, 0, 0]}
                                scale={[1.2, 1.2, 1.2]}
                                
                // scale={[0.8, 0.8, 0.8]}

                castShadow={true}
                isObject3D={true}
                isGroup={true}
              >
                {/* <Model  className="hidden lg:block"/> */}
                <Modelsmall className="block lg:hidden" />
              </group>
            ) : (
              <group
                rotation={[0, Math.PI, 0]}
                position={[0, 0, 0]}
                scale={[0.8, 0.8, 0.8]}

                castShadow={true}
                isObject3D={true}
                isGroup={true}
              >
                {/* <Model  className="hidden lg:block"/> */}
                <Modelsmall className="block lg:hidden" />
              </group>
            )}

            <Environment preset="city" />
          </Suspense>
          {/* <ContactShadows
         position={[-10, 0, -30]}
         scale={50}
         blur={2}
         far={4.5}
       /> */}
          <OrbitControls
            enablePan={false}
            enableZoom={false}
            minPolarAngle={Math.PI / 2.8}
            maxPolarAngle={Math.PI / 2.8}
            enableDamping={false}
          />
        </Canvas>
      </section>
    </>
  );
}
