import { FunctionComponent } from "react";
import image from "../../assets/image/aboutusimage.png";

interface WhyUsProps {}

const WhyUs: FunctionComponent<WhyUsProps> = () => {
  return (
    <div className="w-screen bg-black lg:h-screen">
    <div className="lg:w-[746px] w-full m-auto pt-32 px-5 lg:px-0">
      <h2 className="mb-6 text-3xl text-center text-white lg:text-5xl font-Inria before:w-7 before:h-[3px] relative before:absolute before:-bottom-2 before:bg-white">.Why us</h2>
      <p className="mb-12 text-sm text-center text-white lg:text-base font-inter">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final .
In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final .</p>
   <ul className="flex flex-col justify-center text-center lg:mb-10 lg:justify-between lg:flex-row">
    <li className="mb-7 lg:mb-0">
        <p className="mb-3 text-4xl lg:text-6xl text-mainColor">+12</p>
        <p className="text-base text-white lg:text-xl">Years experience</p>
    </li>
    <li className="mb-7 lg:mb-0">
        <p className="mb-3 text-4xl lg:text-6xl text-mainColor">+10</p>
        <p className="text-base text-white lg:text-xl">Successful Project</p>
    </li>
    <li className="mb-7 lg:mb-0">
        <p className="mb-3 text-4xl lg:text-6xl text-mainColor">+8</p>
        <p className="text-base text-white lg:text-xl">City supporting</p>
    </li>
   </ul>
    </div>
   <img src={image} alt=""  className="h-screen max-w-fit lg:max-w-full lg:w-full lg:h-auto"/>
    </div>
  );
};

export default WhyUs;
