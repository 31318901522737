import { FunctionComponent, useRef, useState } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowRight from "../../assets/icon/arrowRight";

import ArrowLeftSlider from "../../assets/icon/arrowLeftSlider";
import ArrowRightSlider from "../../assets/icon/arrowRightSlider";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import MoreDetailsPage from "../moreDetails/moreDetails";
import { Dialog } from "@material-tailwind/react";
interface BottomLeftProps {
  title: string;
  content: string;
  img: any[];
}

const BottomLeft: FunctionComponent<BottomLeftProps> = ({
  title,
  img,
  content,
}) => {
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [moreDetailsData, setMoreDetailsData] = useState<{
    title: string;
    content: string;
    image: any;
  }>({
    title: "",
    content: "",
    image: "",
  });
  const handleOpen = () => {
    setShowMoreDetails(!showMoreDetails);
  };

  const OnClickSlide = (x: number) => {
    if (x == 1) {
      // alert("salam");
    } else {
      // alert("khodafez");
    }
  };
  const swiperInit = {
    modules: [Navigation],
    navigation: {
      prevEl: "#prevRef",
      nextEl: "#nextRef",
    },
    speed: 1200,
    allowTouchMove: false,
    loop: true,
  };
  const backswiperInit = {
    dir: "rtl",
    modules: [Navigation],
    navigation: {
      prevEl: "#prevRef",
      nextEl: "#nextRef",
    },
    speed: 1700,
    allowTouchMove: false,
    loop: true,
  };
  return (
    <>
      {showMoreDetails && (
        <Dialog open={showMoreDetails} handler={handleOpen} size="xxl">
          <MoreDetailsPage
            title={moreDetailsData.title}
            content={moreDetailsData.content}
            image={moreDetailsData.image}
            handleClick={handleOpen}
          />
        </Dialog>
      )}
      <div id="page1-2" className="relative w-screen h-screen">
        <div className="absolute top-0 bottom-0 left-0 right-0 ">
          <div className="absolute top-0 bottom-0 left-0 right-0 z-20 bg-white dark:bg-black dark:bg-opacity-60 bg-opacity-40 "></div>
          <Fade top big duration={2000}>
            <Swiper {...backswiperInit} className="w-screen h-screen">
              {img &&
                img.length > 0 &&
                img?.map((item) => {
                  return (
                    <SwiperSlide>
                      <img src={item} alt="" className="h-full lg:w-full" />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Fade>
        </div>
        <div className="relative z-30 w-screen h-screen">
          <div className="relative w-full h-full">
            <Fade left big duration={2000}>
              <div className="absolute bottom-14 w-full mx-auto  lg:bottom-14 lg:w-2/3 h-[100px] lg:h-[200px]  lg:left-0 bg-mainColor z-10"></div>
            </Fade>
            <div className="absolute z-20 top-[45%]   lg:right-1/2  lg:w-[600px] w-full mx-auto px-14 lg:px-0 lg:ml-auto  lg:bottom-auto text-white lg:top-1/3">
              <Fade left big duration={2000}>
                <h2 className="mb-5 mr-4 text-3xl font-bold text-black dark:text-white lg:text-5xl font-Inria">
                  {title}
                </h2>
                <p className="mb-6 text-xs text-black lg:text-base dark:text-white">{content}</p>
                <button
                  className="text-base text-black cursor-pointer dark:text-white"
                  onClick={() => {
                    setMoreDetailsData({
                      title: title,
                      content: content,
                      image: img[0],
                    });
                    setShowMoreDetails(true);
                  }}
                >
                  More detail
                  <ArrowRight className="inline ml-2 stroke-black dark:stroke-white" />
                </button>
              </Fade>
            </div>
            <Fade right big duration={2000}>
              <div className="absolute top-[15%] lg:left-[55%] z-20 lg:top-[25%]  lg:mt-auto right-12  imageShadow w-[75%] mx-auto  lg:mx-0 lg:w-[500px] aspect-[4/3]">
                <Swiper {...swiperInit}>
                  {img &&
                    img.length > 0 &&
                    img?.map((item) => {
                      return (
                        <SwiperSlide>
                          <img
                            src={item}
                            alt=""
                            className="w-full cursor-pointer"
                            onClick={() => {
                              setMoreDetailsData({
                                title: title,
                                content: content,
                                image: img[0],
                              });
                              setShowMoreDetails(true);
                            }}
                          />
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </Fade>
          </div>
        </div>
        <div
          id="prevRef"
          className="absolute z-50 right-5 lg:right-10 top-1/2 lg:top-1/2"
        >
          <ArrowLeftSlider className="w-8 transition-all cursor-pointer stroke-black dark:stroke-white hover:stroke-mainColor lg:w-auto" />
        </div>
        <div
          id="nextRef"
          className="absolute z-[9999] left-5 lg:left-10 top-1/2 lg:top-1/2"
        >
          <ArrowLeftSlider className="w-8 transition-all rotate-180 cursor-pointer stroke-black dark:stroke-white hover:stroke-mainColor lg:w-auto " />
          {/* <ArrowRightSlider className="stroke-white hover:stroke-mainColor"/> */}
        </div>
      </div>
    </>
  );
};

export default BottomLeft;
