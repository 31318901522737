import { FunctionComponent, useEffect, useState } from "react";
import loadingImage from "../../assets/cabinet_loop_alpha.gif";
import loadinglogo from "../../assets/logo/loading logo.svg";
import Lottie from 'react-lottie';
import * as animationData from '../../assets/loading.json'
interface LoadingProps {
  loadingTime: number;
}

const Loading: FunctionComponent<LoadingProps> = ({ loadingTime }) => {
  // const [completed, setCompleted] = useState(0);
  // let step = 0.6;
  // let current_progress = 0;
//   useEffect(() => {
//     // const interval = setInterval(function () {
//     //   current_progress += step;
//     //   let progress =
//     //     Math.round((Math.atan(current_progress) / (Math.PI / 2)) * 100 * 10) /
//     //     10;
//     //   setCompleted(progress);
//     //   if (progress >= 100) {
//     //     clearInterval(interval);
//     //   } else if (progress >= 70) {
//     //     step = 0.1;
//     //   }
//     // }, loadingTime);
//   }, []);
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center bg-cover bg-loading-bgImage">
      {/* <p className="loadingPersent">{completed}%</p> */}
      <div className="mb-16 mt-16 loading w-96 before:bg-none lg:before:w-1/3 lg:before:bg-gradient-to-r lg:before:to-[rgba(109,109,109,1)] lg:before:from-[rgba(109,109,109,0)] lg:before:h-[1px] lg:before:fixed lg:before:top-1/2 lg:before:right-0 lg:after:w-1/3 lg:after:bg-gradient-to-l lg:after:to-[rgba(109,109,109,1)] lg:after:from-[rgba(109,109,109,0)] lg:after:n lg:after:h-[1px]  lg:after:fixed lg:after:top-1/2 lg:after:left-0">
         <Lottie options={defaultOptions}/>
      </div>
      {/* <img src={loadinglogo} alt="" /> */}
    </div>
  );
};

export default Loading;
