import { forwardRef } from "react";
import { IconSvgProps } from "./IconSvgProps";

const Arrow = forwardRef<SVGSVGElement, IconSvgProps>((props, ref) => {
  const {
    className,
    size = 15,
    color = "#fff",
    strokeWidth = 1,
  } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 14530">
    <path id="Vector 87" d="M25 1L1 25" stroke="white" stroke-width="1.5"/>
    <path id="Vector 88" d="M1 1L25 25" stroke="white" stroke-width="1.5"/>
    </g>
    </svg>
    
  );
});

Arrow.displayName = "Arrow";

export default Arrow;
