import { Fragment, FunctionComponent, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore from 'swiper';
import { Navigation } from "swiper/modules";
import closet1 from "../../assets/image/closet1.jpg";
import closet2 from "../../assets/image/closet2.png";
import closet3 from "../../assets/image/closet3.png";
import Arrow from "../../assets/icon/arrow";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import Fade from "react-reveal/Fade";

import CloseIcon from "../../assets/icon/close";
import FooterMain from "../../components/footer";
interface ClosetProps {}
SwiperCore.use([Navigation]);
const Closet: FunctionComponent<ClosetProps> = () => {
 
  const [open, setOpen] = useState(false);
  const [swiperImageSelect, setSwiperImageSelect] = useState(Number);
  const handleOpen = (e: any) => {
    e.preventDefault();
    setOpen(!open);
    // setSwiperImageSelect(number);
  };
  const swiperInit = {
    modules: [Navigation],
    navigation: {
      nextEl: "#button_prev",
      prevEl:"#button_next",
    },
    dir: "rtl",
    className: "lg:w-3/6 px-3 lg:px-0",
    // style: { display: "grid" },

    slidesPerView: 1,
    speed: 1000,
    allowTouchMove: true,
    loop: true,
  };
  const mainswiperinit = {
    modules: [Navigation],
    navigation: {
      prevEl: "#main_perv",
      nextEl: "#main_next",
    },
    dir: "rtl",
    className: "",
    // style: { display: "grid" },
    spaceBetween: 50,
    slidesPerView: 3.5,
    loop: true,
    breakpoints: {
      "@0.00": {
        slidesPerView: 1.4,
        spaceBetween: 50,
      },
      "@0.75": {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      "@1.00": {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      "@1.50": {
        slidesPerView: 4,
        spaceBetween: 50,
      },
    },
  };
  return (
    <div
      id="page1-2"
      className="flex flex-col justify-start w-screen h-screen pt-20 overflow-auto bg-black lg:pt-40 bg-opacity-90"
    >
      <Fragment>
        <Dialog
          open={open}
          handler={handleOpen}
          className="bg-black "
          size="xxl"
        >
          <div className="w-screen h-screen py-32">
            <button onClick={handleOpen} className="absolute top-10 left-10 lg:top-30 lg:left-32">
              <CloseIcon />
            </button>
           <div className="w-full mx-auto lg:w-3/4">
           <Swiper {...swiperInit}>
              <SwiperSlide>
                <div className="w-full overflow-hidden ">
                  <img src={closet1} alt="" className="w-full aspect-square" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full overflow-hidden ">
                  <img src={closet2} alt="" className="w-full aspect-square" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full overflow-hidden ">
                  <img src={closet3} alt="" className="w-full aspect-square" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full overflow-hidden ">
                  <img src={closet1} alt="" className="w-full aspect-square" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full overflow-hidden ">
                  <img src={closet2} alt="" className="w-full aspect-square" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full overflow-hidden ">
                  <img src={closet3} alt="" className="w-full aspect-square" />
                </div>
              </SwiperSlide>
            </Swiper>
           </div>
            <div
              className="border border-white rounded-full absolute left-[30%] lg:left-10 z-50  flex items-center justify-center cursor-pointer  top-3/4 lg:top-1/2 lg:bg-[#171717]  w-12 h-12"
             id="button_next"
            >
              <Arrow />
            </div>
            <div
              className=" border border-white rounded-full absolute right-[30%] lg:right-10 z-50 flex items-center justify-center cursor-pointer top-3/4 lg:top-1/2 lg:bg-[#171717] w-12 h-12"
           id="button_prev"
            >
              <Arrow className="rotate-180" />
            </div>
          </div>
        </Dialog>
      </Fragment>
      <h2 className="mb-10 text-5xl text-center text-white lg:mb-20 font-Inria">
        Closet
      </h2>
      <p className="text-sm text-white  lg:w-[708px] text-center mx-auto mb-8 px-3">
        In publishing and graphic design, Lorem ipsum is a placeholder text
        commonly used to demonstrate the visual form of a document or a typeface
        without relying on meaningful content.In publishing and graphic design,
        Lorem ipsum is a placeholder text commonly used to demonstrate the
        visual form of a document or a typeface without relying on meaningful
        content.
      </p>
      <div className="relative w-screen lg:pb-5">
        <Swiper {...mainswiperinit}>
          <SwiperSlide>
            <div className="w-full overflow-hidden " onClick={handleOpen}>
              <img src={closet1} alt="" className="w-full cursor-pointer aspect-square" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full overflow-hidden " onClick={handleOpen}>
              <img src={closet2} alt="" className="w-full cursor-pointer aspect-square" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full overflow-hidden " onClick={handleOpen}>
              <img src={closet3} alt="" className="w-full cursor-pointer aspect-square" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full overflow-hidden " onClick={handleOpen}>
              <img src={closet1} alt="" className="w-full cursor-pointer aspect-square" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full overflow-hidden " onClick={handleOpen}>
              <img src={closet2} alt="" className="w-full cursor-pointer aspect-square" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full overflow-hidden " onClick={handleOpen}>
              <img src={closet3} alt="" className="w-full cursor-pointer aspect-square" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full overflow-hidden " onClick={handleOpen}>
              <img src={closet1} alt="" className="w-full cursor-pointer aspect-square" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full overflow-hidden " onClick={handleOpen}>
              <img src={closet2} alt="" className="w-full cursor-pointer aspect-square" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full overflow-hidden " onClick={handleOpen}>
              <img src={closet3} alt="" className="w-full cursor-pointer aspect-square" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full overflow-hidden " onClick={handleOpen}>
              <img src={closet1} alt="" className="w-full cursor-pointer aspect-square" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full overflow-hidden " onClick={handleOpen}>
              <img src={closet2} alt="" className="w-full cursor-pointer aspect-square" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full overflow-hidden " onClick={handleOpen}>
              <img src={closet3} alt="" className="w-full cursor-pointer aspect-square" />
            </div>
          </SwiperSlide>
        </Swiper>
        <div
          className="absolute hidden left-10 z-[100] rounded-full lg:flex items-center justify-center cursor-pointer top-1/3 lg:top-1/2 bg-[#171717] w-12 h-12"
          id="main_next"
          // ref={nextRef3}
        >
          <Arrow />
        </div>
        <div
          className="absolute hidden right-10 z-[100] rounded-full lg:flex items-center justify-center cursor-pointer top-1/3 lg:top-1/2 bg-[#171717] w-12 h-12"
          id="main_perv"
          // ref={prevRef3}
        >
          <Arrow className="rotate-180" />
        </div>
      </div>
      <FooterMain classNameprops="relative lg:absolute " />
    </div>
  );
};

export default Closet;
