import { FunctionComponent, useRef, useState } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowRight from "../../assets/icon/arrowRight";

import ArrowLeftSlider from "../../assets/icon/arrowLeftSlider";
import ArrowRightSlider from "../../assets/icon/arrowRightSlider";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import MoreDetailsPage from "../moreDetails/moreDetails";
import { Dialog } from "@material-tailwind/react";
interface TopRightProps {
  title: string;
  content: string;
  img: any[];
}

const TopRight: FunctionComponent<TopRightProps> = ({
  title,
  img,
  content,
}) => {
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [moreDetailsData, setMoreDetailsData] = useState<{
    title: string;
    content: string;
    image: any;
  }>({
    title: "",
    content: "",
    image: "",
  });
  const handleOpen = () => {
    setShowMoreDetails(!showMoreDetails);
  };

  const OnClickSlide = (x: number) => {
    if (x == 1) {
      // alert("salam");
    } else {
      // alert("khodafez");
    }
  };
  const swiperInit = {
    modules: [Navigation],
    navigation: {
      prevEl: "#prevRef",
      nextEl: "#nextRef",
    },
    speed: 1200,
    allowTouchMove: false,
    loop: true,
  };
  const backswiperInit = {
    dir: "rtl",
    modules: [Navigation],
    navigation: {
      prevEl: "#prevRef",
      nextEl: "#nextRef",
    },
    speed: 1700,
    allowTouchMove: false,
    loop: true,
  };
  return (
   <>
    {showMoreDetails && (
        <Dialog open={showMoreDetails} handler={handleOpen} size="xxl">
          <MoreDetailsPage
            title={moreDetailsData.title}
            content={moreDetailsData.content}
            image={moreDetailsData.image}
            handleClick={handleOpen}
          />
        </Dialog>
      )}
    <div id="page1-2" className="relative w-screen h-screen">
      <div className="absolute top-0 bottom-0 left-0 right-0 ">
        {/* <div className="absolute top-0 bottom-0 left-0 right-0 z-20 bg-white dark:bg-opacity-40 dark:bg-black bg-opacity-40 "></div> */}
        <div className="absolute top-0 bottom-0 left-0 right-0 z-20 bg-white dark:bg-black dark:bg-opacity-60 bg-opacity-40 "></div>
       
        <Fade top big duration={1300}>
          <Swiper {...backswiperInit} className="w-screen h-screen">
            {img &&
              img.length > 0 &&
              img?.map((item) => {
                return (
                  <SwiperSlide>
                    <img src={item} alt="" className="h-full lg:w-full" />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </Fade>
      </div>
      <div className="relative z-30 w-screen h-screen">
        <div className="relative w-full h-full">
          <Fade top big duration={2000}>
            <div className="absolute bottom-0 w-[100px] mx-auto  lg:top-0 lg:w-[400px] h-1/4 lg:h-2/3 lg:right-[10%] bg-mainColor z-10"></div>
          </Fade>
          <div className="absolute z-20 top-[20%]   lg:left-1/2  lg:w-[600px] w-full mx-auto px-10 lg:px-0 lg:ml-auto  lg:bottom-auto text-white lg:top-1/3">
            <Fade top duration={2000}>
              <h2 className="mb-5 mr-4 text-3xl font-bold text-black dark:text-white lg:text-5xl font-Inria">
                {title}
              </h2>
            </Fade>
            <Fade top duration={1800}>
              <p className="mb-6 text-xs text-black dark:text-white lg:text-base">{content}</p>
            </Fade>
            <Fade top duration={2000}>
              <button
              className="text-black cursor-pointer dark:text-white "
               onClick={() => {
                    setMoreDetailsData({
                      title: title,
                      content: content,
                      image: img[0],
                    });
                    setShowMoreDetails(true);
                  }}
                  >
                More detail
                <ArrowRight className="inline ml-2 stroke-black dark:stroke-white" />
              </button>
            </Fade>
          </div>
          <Fade bottom duration={2000}>
            <div className="absolute top-[40%] lg:right-[55%] z-20 lg:top-[33%] mt-[35%]  lg:mt-auto right-12  imageShadow w-[75%] mx-auto  lg:mx-0 lg:w-[500px] aspect-[4/3]">
              <Swiper {...swiperInit}>
                {img &&
                  img.length > 0 &&
                  img?.map((item) => {
                    return (
                      <SwiperSlide>
                        <img src={item} alt="" className="w-full cursor-pointer"  onClick={() => {
                    setMoreDetailsData({
                      title: title,
                      content: content,
                      image: img[0],
                    });
                    setShowMoreDetails(true);
                  }}/>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </Fade>
        </div>
      </div>
      <div
        id="prevRef"
        className="absolute z-50 right-5 lg:right-10 top-1/2 lg:top-1/2"
      >
        <ArrowLeftSlider className="w-8 transition-all cursor-pointer stroke-black dark:stroke-white hover:stroke-mainColor lg:w-auto" />
      </div>
      <div
        id="nextRef"
        className="absolute z-[9999] left-5 lg:left-10 top-1/2 lg:top-1/2"
      >
        <ArrowLeftSlider className="w-8 transition-all rotate-180 cursor-pointer stroke-black dark:stroke-white hover:stroke-mainColor lg:w-auto " />
        {/* <ArrowRightSlider className="stroke-white hover:stroke-mainColor"/> */}
      </div>
    </div>
    </>
  );
};

export default TopRight;
