import { FunctionComponent, ReactNode, useEffect, useRef, useState } from "react";
import ReactPageScroller, { ReactPageScrollerProps } from "react-page-scroller";
import MotionSection from "../../components/pageViews/MotionSection";
import SampleViewOne from "../../components/pageViews/SampleViewOne";
import MoreDetailsSlider from "../../components/pageViews/moreDetailsSlider";
import kitchenImage from "../../assets/image/image 43 copy.jpg";
import AboutUs from "../aboutUs/aboutus";
import FooterMain from "../../components/footer";
import MoreDetailsPage from "../../components/moreDetails/moreDetails";

interface KitchenProps {}

const Kitchen: FunctionComponent<KitchenProps> = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [disableScrolling, setDisableScrolling] = useState(false);
  // useEffect(()=>{
  //   console.log("useeefect",disableScrolling);

  // },[disableScrolling])
  const handlePageChange = (number: number) => {
    setCurrentPage(number);
    setDisableScrolling(false);
    // console.log("pageScroll",disableScrolling);
  };
  const handleSwiperSlideChange = (swiper: any) => {
    setCurrentPage(currentPage);
    setDisableScrolling(true);
    // console.log("swipper",disableScrolling);
  };
  const pageScrollerProps: ReactPageScrollerProps = {
    pageOnChange: handlePageChange,
    customPageNumber: currentPage,
    animationTimerBuffer: 1000,
    animationTimer: 1000,
    renderAllPagesOnFirstRender: true,
    blockScrollUp: disableScrolling,
    blockScrollDown: disableScrolling,
  };
  return (
    <div>
      <ReactPageScroller {...pageScrollerProps}>
        <MotionSection handlePageChange={handlePageChange} title={"Kitchen"} />
        <SampleViewOne
          title={"Kitchen"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
          }
          img={[kitchenImage, kitchenImage, kitchenImage]}
        />
        <MoreDetailsSlider
          onSlideChange={handleSwiperSlideChange}
          title={" More Cabinet design"}
          dialogTitle={"Cabinet"}
          img={[
            kitchenImage,
            kitchenImage,
            kitchenImage,
            kitchenImage,
            kitchenImage,
            kitchenImage,
          ]}
          content={[
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
          ]}
        />
        {/* <AboutUs> */}

        {/* </AboutUs> */}
      </ReactPageScroller>
    </div>
  );
};

export default Kitchen;
