
import { forwardRef } from "react";
import { IconSvgProps } from "./IconSvgProps";

const Instagram = forwardRef<SVGSVGElement, IconSvgProps>((props, ref) => {
    const { className, size = 24, color = "currentColor", strokeWidth = 1 } = props;

    return (
        <svg width="30" className={className} height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.7494 20.3676V13.2038V9.63176C23.7494 7.76643 22.2323 6.25 20.367 6.25H9.63176C7.76643 6.25 6.25 7.76643 6.25 9.63176V13.2038V20.3676C6.25 22.2336 7.76643 23.75 9.63176 23.75H20.3676C22.2323 23.75 23.7494 22.2336 23.7494 20.3676ZM18.0802 14.9999C18.0802 16.6985 16.6964 18.0798 14.999 18.0798C13.301 18.0798 11.9184 16.6979 11.9203 14.9993C11.9209 14.3287 12.1384 13.7096 12.5028 13.204C13.0637 12.4281 13.9728 11.9199 15.0009 11.9199C16.0283 11.9199 16.9381 12.4287 17.4977 13.2046C17.8608 13.7103 18.0802 14.3294 18.0802 14.9999ZM21.7247 11.2316V8.64985V8.26562L21.3387 8.26686L18.7582 8.27492L18.7681 11.2415L21.7247 11.2316ZM14.9997 19.7844C17.6391 19.7844 19.7857 17.6365 19.7857 14.9984C19.7857 14.3638 19.6575 13.7571 19.43 13.2031H22.0433V20.367C22.0433 21.2928 21.2904 22.0427 20.3664 22.0427H9.63116C8.70593 22.0427 7.95422 21.2928 7.95422 20.367V13.2031H10.5663C10.3407 13.7571 10.2143 14.3638 10.2143 14.9984C10.2143 17.6365 12.3604 19.7844 14.9997 19.7844Z" fill="white"/>
        <mask id="mask0_1141_135397" style={{maskType:"luminance"}}  maskUnits="userSpaceOnUse" x="6" y="6" width="18" height="18">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.7494 20.3676V13.2038V9.63176C23.7494 7.76643 22.2323 6.25 20.367 6.25H9.63176C7.76643 6.25 6.25 7.76643 6.25 9.63176V13.2038V20.3676C6.25 22.2336 7.76643 23.75 9.63176 23.75H20.3676C22.2323 23.75 23.7494 22.2336 23.7494 20.3676ZM18.0802 14.9999C18.0802 16.6985 16.6964 18.0798 14.999 18.0798C13.301 18.0798 11.9184 16.6979 11.9203 14.9993C11.9209 14.3287 12.1384 13.7096 12.5028 13.204C13.0637 12.4281 13.9728 11.9199 15.0009 11.9199C16.0283 11.9199 16.9381 12.4287 17.4977 13.2046C17.8608 13.7103 18.0802 14.3294 18.0802 14.9999ZM21.7247 11.2316V8.64985V8.26562L21.3387 8.26686L18.7582 8.27492L18.7681 11.2415L21.7247 11.2316ZM14.9997 19.7844C17.6391 19.7844 19.7857 17.6365 19.7857 14.9984C19.7857 14.3638 19.6575 13.7571 19.43 13.2031H22.0433V20.367C22.0433 21.2928 21.2904 22.0427 20.3664 22.0427H9.63116C8.70593 22.0427 7.95422 21.2928 7.95422 20.367V13.2031H10.5663C10.3407 13.7571 10.2143 14.3638 10.2143 14.9984C10.2143 17.6365 12.3604 19.7844 14.9997 19.7844Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_1141_135397)">
        <rect width="30" height="30" fill="white"/>
        </g>
        </svg>
        
        
    );
});

Instagram.displayName = "Instagram";

export default Instagram;
