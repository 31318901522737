import { forwardRef } from "react";
import { IconSvgProps } from "./IconSvgProps";

const ArrowRight = forwardRef<SVGSVGElement, IconSvgProps>((props, ref) => {
    const { className, size = 24, color = "currentColor", strokeWidth = 1 } = props;

    return (
        <svg width="20" height="20"  className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.16699 10H15.8337" stroke={color} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 4.16699L15.8333 10.0003L10 15.8337" stroke={color} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        
        
    );
});

ArrowRight.displayName = "ArrowRight";

export default ArrowRight;
