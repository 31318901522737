import { FunctionComponent, ReactNode, useState } from "react";
import ReactPageScroller, { ReactPageScrollerProps } from "react-page-scroller";
import TopCenter from "../../components/pageViews/topCenter";
import livingRoomImage from "../../assets/image/familyroom.jpg";
import diningRoomImage from "../../assets/image/livingroom.png";
import TopRight from "../../components/pageViews/topright";
import MoreDetailsSlider from "../../components/pageViews/moreDetailsSlider";
import chair1 from "../../assets/image/chair.jpg";
import chair2 from "../../assets/image/chair2.jpg";
import chair3 from "../../assets/image/chair3.jpg";
import FooterMain from "../../components/footer";
interface LivingRoomProps {}

const LivingRoom: FunctionComponent<LivingRoomProps> = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [disableScrolling, setDisableScrolling] = useState(false);
  // useEffect(()=>{
  //   console.log("useeefect",disableScrolling);

  // },[disableScrolling])
  const handlePageChange = (number: number) => {
    setCurrentPage(number);
    setDisableScrolling(false);
    // console.log("pageScroll",disableScrolling);
  };
  const handleSwiperSlideChange = (swiper: any) => {
    setCurrentPage(currentPage);
    setDisableScrolling(true);
    // console.log("swipper",disableScrolling);
  };
  const pageScrollerProps: ReactPageScrollerProps = {
    pageOnChange: handlePageChange,
    customPageNumber: currentPage,
    animationTimerBuffer: 1000,
    animationTimer: 1000,
    renderAllPagesOnFirstRender: true,
    blockScrollUp: disableScrolling,
    blockScrollDown: disableScrolling,
  };
  return (
    <>
      <ReactPageScroller {...pageScrollerProps}>
        <TopCenter
          title={"Family room"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
          }
          img={[livingRoomImage, livingRoomImage, livingRoomImage]}
        ></TopCenter>
        <TopRight
          title={"Dining Room"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
          }
          img={[diningRoomImage, diningRoomImage, diningRoomImage]}
        />
        <MoreDetailsSlider
          onSlideChange={handleSwiperSlideChange}
          title={" More Cabinet design"}
          dialogTitle={"Living Room"}
          img={[chair1, chair2, chair3, chair1, chair2, chair3]}
          content={[
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
          ]}
        >
          <FooterMain />
        </MoreDetailsSlider>
      </ReactPageScroller>
    </>
  );
};

export default LivingRoom;
