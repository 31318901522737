import { Fragment, FunctionComponent, ReactNode, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import cabinet1 from "../../assets/image/image 43 copy.jpg";
import cabinet2 from "../../assets/image/image 43 copy.jpg";
import Arrow from "../../assets/icon/arrow";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import FooterMain from "../footer";

interface MoreDetailsSliderProps {
  title: string;
  dialogTitle?: string;
  img: any[];
  content: string[];
  children?:ReactNode;
  onSlideChange?:any;
}

const MoreDetailsSlider: FunctionComponent<MoreDetailsSliderProps> = ({
  title,
  img,
  content,
  dialogTitle,
  children,
  onSlideChange
}) => {
  const [open, setOpen] = useState(false);
  const [swiperImageSelect, setSwiperImageSelect] = useState(Number);
 
  const handleOpen = (number: number) => {
    setOpen(!open);
    setSwiperImageSelect(number);
  };
  const swiperInit = {
    modules: [Navigation],
    spaceBetween: 50,
    slidesPerView: 4.5,
    onSlideChange:onSlideChange,
    loop: true,
    allowTouchMove: false,
    navigation: {
      prevEl: "#prevRef",
      nextEl: "#nextRef",
    },
    breakpoints: {
      "@0.00": {
        slidesPerView: 1.4,
        spaceBetween: 50,
      },
      "@0.75": {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      "@1.00": {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      "@1.50": {
        slidesPerView: 4,
        spaceBetween: 50,
      },
    },
  };
  return (
    <div
      id="page1-2"
      className="relative flex flex-col justify-start w-screen h-screen bg-gray-100 dark:bg-black bg-opacity-90 "
    >
      <Fragment>
        <Dialog open={open} handler={handleOpen} size="xxl">
          <section
            className="flex flex-col h-full lg:flex-row"
            onClick={() => handleOpen(swiperImageSelect)}
          >
            <Fade left>
              <section className="flex items-center justify-center w-full lg:p-40 lg:w-1/2 lg:h-full">
                <img src={img[swiperImageSelect]} alt="" className="w-full" />
              </section>
            </Fade>
            <Fade right>
              <section className="flex items-start w-full h-full px-10 bg-black lg:items-center lg:p-0 lg:w-1/2 lg:h-full ">
                <div className="lg:border lg:border-l-0 border-mainColor lg:my-[100px] lg:mr-[80px] lg:p-[55px] lg:pl-20 relative">
                  <h3 className="left-0 py-10 text-3xl bg-black lg:p-5 lg:absolute lg:text-5xl -top-12 text-mainColor font-Inria">
                    <Arrow
                      className="inline-block w-3 ml-2 mr-3 lg:w-6"
                      color="#FF8A00"
                      size={24}
                    />
                    {dialogTitle} {swiperImageSelect}
                  </h3>
                  <p className="font-light text-white leading-[200%]">
                    {content[swiperImageSelect]}
                  </p>
                </div>
              </section>
            </Fade>
          </section>
        </Dialog>
      </Fragment>
      <Fade bottom big duration={2000}>
        <h2 className="mb-10 text-2xl text-center text-black pt-28 dark:text-white lg:text-5xl font-Inria lg:pt-40">
          {title}
        </h2>
      </Fade>
      <Fade right big duration={2500}>
      <div className="relative w-screen px-5 lg:px-0">
        <Swiper {...swiperInit} className="" style={{ display: "grid" }}  >
          {img &&
            img.length > 0 &&
            img.map((item, index) => {
              return (
                <SwiperSlide>
                  <div
                    className={`lg:w-[350px] aspect-square overflow-hidden mx-auto cursor-pointer ${
                      index === swiperImageSelect && `w-full`
                    }`}
                    onClick={() => handleOpen(index)}
                  >
                    <img src={item} alt=""  />
                  </div>
                </SwiperSlide>
              );
            })}

          <div
            className="absolute flex  left-1 lg:left-10 z-50 rounded-full lg:flex items-center justify-center cursor-pointer top-1/3 lg:top-1/2 bg-[#171717] w-6 h-6 lg:w-12 lg:h-12"
            id="nextRef"
          >
            <Arrow  className="w-2 "/>
          </div>
          <div
            className="absolute flex  right-1 lg:right-10 z-50 rounded-full lg:flex items-center justify-center cursor-pointer top-1/3 lg:top-1/2 bg-[#171717] w-6 h-6  lg:w-12 lg:h-12"
            id="prevRef"
          >
            <Arrow className="w-2 rotate-180" />
          </div>
        </Swiper>
      </div>
      </Fade>
      {children}
      <FooterMain />
    </div>
  );
};

export default MoreDetailsSlider;
