import { FunctionComponent, ReactNode, useState } from "react";
import ReactPageScroller, { ReactPageScrollerProps } from "react-page-scroller";
import bathroom1 from "../../assets/image/bathroom1.png";
import bathroom2 from "../../assets/image/bathroom2.jpeg";
import bathroom3 from "../../assets/image/bathroom3.jpeg";
import bathroom4 from "../../assets/image/bathroom4.png";
import bathroom5 from "../../assets/image/bathroom5.jpeg";
import TopCenter from "../../components/pageViews/topCenter";
import TopRight from "../../components/pageViews/topright";
import BottomLeft from "../../components/pageViews/bottomLeft";
import MoreDetailsSlider from "../../components/pageViews/moreDetailsSlider";
import FooterMain from "../../components/footer";
interface BathroomProps {}

const Bathroom: FunctionComponent<BathroomProps> = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [disableScrolling, setDisableScrolling] = useState(false);
  // useEffect(()=>{
  //   console.log("useeefect",disableScrolling);

  // },[disableScrolling])
  const handlePageChange = (number: number) => {
    setCurrentPage(number);
    setDisableScrolling(false);
    // console.log("pageScroll",disableScrolling);
  };
  const handleSwiperSlideChange = (swiper: any) => {
    setCurrentPage(currentPage);
    setDisableScrolling(true);
    // console.log("swipper",disableScrolling);
  };
  const pageScrollerProps: ReactPageScrollerProps = {
    pageOnChange: handlePageChange,
    customPageNumber: currentPage,
    animationTimerBuffer: 1000,
    animationTimer: 1000,
    renderAllPagesOnFirstRender: true,
    blockScrollUp: disableScrolling,
    blockScrollDown: disableScrolling,
  };
  return (
    <>
      <ReactPageScroller {...pageScrollerProps}>
        <TopCenter
          title={"Bathroom 1"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
          }
          img={[bathroom1, bathroom1, bathroom1]}
        />
        <TopRight
          title={"Bathroom 2"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
          }
          img={[bathroom2, bathroom2, bathroom2]}
        />
        <BottomLeft
          title={"Bathroom 3"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
          }
          img={[bathroom3, bathroom3, bathroom3]}
        />
        <TopCenter
          title={"Bathroom 4"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
          }
          img={[bathroom4, bathroom4, bathroom4]}
        />
        <TopRight
          title={"Bathroom 5"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
          }
          img={[bathroom5, bathroom3, bathroom5]}
        />

        <MoreDetailsSlider
          onSlideChange={handleSwiperSlideChange}
          title={" More Bathroom design"}
          dialogTitle={"Bath room "}
          img={[bathroom1, bathroom2, bathroom3, bathroom4, bathroom5]}
          content={[
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
          ]}
        >
          <FooterMain />
        </MoreDetailsSlider>
      </ReactPageScroller>
    </>
  );
};

export default Bathroom;
