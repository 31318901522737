import { FunctionComponent, useEffect, useState } from "react";
import Jump from "react-reveal/Jump";
import Arrow from "../../assets/icon/arrow";
import Render3D from "./render3D";
import logo from "../../assets/logo/logo.svg";
import logolight from "../../assets/logo/logolight.svg";
interface HomePageVerticalProps {

}

const HomePageVertical: FunctionComponent<HomePageVerticalProps> = () => {
  const [scrollEnable, setscrollEnable] = useState(false);
  const [hiddenEl, sethiddenEl] = useState(false);
  const [Homeloading, setHomeloading] = useState(false);
  const [orientation, setOrientation] = useState<number>(window.orientation);

  const handleOrientationChange = () => {
    setOrientation(window.orientation);
  };

  useEffect(() => {
    // Add event listener for orientation change
    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      // Clean up the event listener when component is unmounted
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setHomeloading(true);
    }, 3000);
  }, []);
  return (
    <div
      className="relative lg:w-screen lg:h-screen lg:overflow-hidden"
      onWheel={() => {
        if (!scrollEnable) {
          // console.log("ss", scrollEnable);
        }
        setscrollEnable(true);
        // console.log("ss", scrollEnable);
        setTimeout(() => {
          sethiddenEl(true);
          setHomeloading(true);
        }, 1000);
      }}
      onTouchStart={() => {
        if (!scrollEnable) {
          // console.log("ss", scrollEnable);
        }
        setscrollEnable(true);
        // console.log("ss", scrollEnable);
        setTimeout(() => {
          sethiddenEl(true);
          setHomeloading(true);
        }, 1000);
      }}
    >
      <section
        className={`absolute items-center justify-center w-full h-[100vh] bg-center lg:bg-top bg-no-repeat bg-cover ${orientation !== 0 ? 'lg:h-screen lg:bg-cover content-stretch overflow-hidden lg:dark:bg-HomeBg-dark lg:bg-HomeBg-light dark:bg-HomeBg-dark bg-HomeBg-light ':'lg:h-screen lg:bg-cover content-stretch overflow-hidden lg:dark:bg-HomeBg-dark lg:bg-HomeBg-light dark:bg-HomeWBg-dark bg-HomeVBg-light '}`}
        // style={{ backgroundImage: `url(${darkBgImage})` }}
      >
        <div className="absolute inset-0 z-10 flex justify-center h-[100vh] w-[100vw] lg:w-auto lg:h-screen overflow-hidden">
          {/* <img src={darkBgImage} alt=""   className="h-screen lg:max-w-full lg:h-auto max-w-none lg:w-full"/> */}
        </div>
        <div className="absolute inset-0 z-20 dark:bg-black dark:bg-opacity-40 bg-opacity-40 bg-white h-[100vh] w-[100vw] lg:w-auto lg:h-auto"></div>
        <div className={`absolute ${orientation !== 0 ? " -bottom-[40px] ":" -bottom-[50px] "} lg:bottom-[30px] lg:relative z-30 flex justify-center items-end w-[100vw] lg:w-screen mx-auto mt-[200px] lg:mt-[50px] h-[70%]  mb-10 lg:h-full image`}>
          {Homeloading && <Render3D  orientation={orientation}/>}
          {/* <Render3D  /> */}
        </div>
      </section>
      <div
        className={`absolute w-screen h-[100vh] lg:h-screen flex-col  z-50 top-0 bottom-0 left-0 right-0 flex items-center justify-center  dark:bg-black bg-white dark:bg-opacity-60 bg-opacity-60  ${
          scrollEnable && "animate-ping"
        }
        ${hiddenEl && "hidden"}
        `}
      >
        <h1 className="mb-10 text-4xl text-black lg:text-9xl dark:text-white font-Inria ">
          {/* SQUARE */}
          <img src={logo} alt="" className="p-3 bg-black dark:bg-transparent" />
        </h1>
        <Jump forever={true} delay={2000} count={0} duration={8000} wait={200}>
          <div className="px-5 py-4 border border-black rounded-full dark:border-white ">
            <div className="flex flex-col items-center justify-center aspect-square">
              <span className="text-black dark:text-white ">scroll</span>
              <Arrow
                className="p-2 -rotate-90 bg-black rounded-full dark:bg-transparent"
                size={25}
                color="#fff"
              />
            </div>
          </div>
        </Jump>
      </div>
    </div>
  );
};

export default HomePageVertical;
