
import { forwardRef } from "react";
import { IconSvgProps } from "./IconSvgProps";

const PhoneNumberIcon = forwardRef<SVGSVGElement, IconSvgProps>((props, ref) => {
    const { className, size = 24, color = "currentColor", strokeWidth = 1 } = props;

    return (
        <svg width="24" height="24"  className={className} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path d="M14.4183 5.48976C13.9422 5.40182 13.505 5.70561 13.4144 6.17029C13.3238 6.63497 13.6285 7.08866 14.0916 7.1796C15.4859 7.45141 16.5624 8.53067 16.8353 9.92971V9.93071C16.913 10.3334 17.2675 10.6262 17.6759 10.6262C17.7306 10.6262 17.7854 10.6212 17.8412 10.6112C18.3043 10.5183 18.609 10.0656 18.5184 9.59994C18.1111 7.51037 16.5027 5.89648 14.4183 5.48976Z" fill="#FF8A00"/>
        <path d="M14.3558 2.00793C14.1328 1.97595 13.9087 2.04191 13.7304 2.18381C13.5472 2.32771 13.4326 2.53557 13.4078 2.76841C13.355 3.23908 13.6946 3.66479 14.1646 3.71776C17.4063 4.07951 19.9259 6.60477 20.2904 9.85654C20.3392 10.2922 20.7047 10.621 21.1409 10.621C21.1738 10.621 21.2057 10.619 21.2385 10.615C21.4666 10.59 21.6698 10.4771 21.8132 10.2972C21.9556 10.1174 22.0203 9.89351 21.9944 9.66467C21.5403 5.60746 18.4002 2.45862 14.3558 2.00793Z" fill="#FF8A00"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0317 12.9724C15.0208 16.9604 15.9258 12.3467 18.4656 14.8848C20.9143 17.3328 22.3216 17.8232 19.2192 20.9247C18.8306 21.237 16.3616 24.9943 7.6846 16.3197C-0.993478 7.644 2.76158 5.17244 3.07397 4.78395C6.18387 1.67385 6.66586 3.08938 9.11449 5.53733C11.6544 8.0765 7.04266 8.98441 11.0317 12.9724Z" fill="#FF8A00"/>
        </svg>
        
        
    );
});

PhoneNumberIcon.displayName = "PhoneNumberIcon";

export default PhoneNumberIcon;
